import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// receive prop
// const Instruction = (props) => {
//     return( <div>Instruction</div>
//     );
const useStyles = makeStyles({
    instruction: {
        display: "flex",
        maxHeight: "70vh",
        overflow: "auto",
        padding: "10px",
        fontWeight: "normal",
    },
});

const Instruction = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.instruction}>
            <div dangerouslySetInnerHTML={{ __html: props.data }} />
        </div>
    );
    return;
};

export default Instruction;
