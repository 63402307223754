import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { OutlinedInput } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: "scroll",
    height: "calc(100vh - 30vh)",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: "23px",
    fontWeight: "600",
    fontFamily: "'Poppins', sansSerif",
    fontStyle: "normal",
    lineHeight: "34px",
    marginBottom: "8px",
  },
  newHeadlineH6: {
    fontSize: "19px",
    fontWeight: "500",
    fontFamily: "'Poppins', sansSerif",
    fontStyle: "normal",
    lineHeight: "28px",
    marginBottom: "4px",
  },
  codeUse: {
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: "'Poppins', sansSerif",
    fontStyle: "normal",
    lineHeight: "27px",
    marginBottom: "8px",
    color: "#818999",
  },
  alignRight: {
    textAlign: "right",
  },
  containerRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  numberBlock: {
    minWidth: "25px",
  },
  blue: {
    color: "#234B88",
  },
  bottomBorder: {
    border: "1px solid #E6E6E6",
    borderWidth: "0 0 1px 0",
    paddingTop: "16px",
  },
}));

const Score = ({ scoreObject }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={12} lg={12}>
          <h3 className={classes.title}>Your Score</h3>
        </Grid>

        {scoreObject.arrayQuestion.map((item, index) => {
          return (
            <Grid
              key={index}
              item
              xs={12}
              lg={12}
              className={classes.bottomBorder}
            >
              <Grid container spacing={0}>
                <Grid item xs={9} lg={9} spacing={0}>
                  <div className={classes.containerRow}>
                    <div className={classes.numberBlock}>
                      <h2 className={classes.newHeadlineH6}>
                        {index + 1}
                        {". "}
                      </h2>
                    </div>
                    <div>
                      <h2 className={classes.newHeadlineH6}>
                        {item.question_code} - {item.question_name}{" "}
                      </h2>
                      <h4 className={`${classes.codeUse}`}>
                        {item.language == "-" ? "-" : `Using ${item.language}`}
                      </h4>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={3} lg={3} spacing={0}>
                  <h2
                    className={`${classes.newHeadlineH6} ${classes.alignRight}`}
                  >
                    <span className={`${classes.blue}`}>
                      {isNaN(item.result) ? "-" : item.result}
                    </span>{" "}
                    (of {item.maxScore})
                  </h2>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default Score;
