import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import { Redirect, useLocation } from "react-router-dom";

// icons
import InstructionIcon from "@material-ui/icons/Assignment";
import CodeIcon from "@material-ui/icons/Code";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

import Countdown, { zeroPad } from "react-countdown";
import unescapeJs from "unescape-js";
import Loading from "../loading/Loading";
import Axios from "axios";
import Swal from "sweetalert2";
import logo from "../../assets/icons/logo.png";

// components
import UserInput from "../../components/userInput/UserInput";
import Instruction from "../../components/instruction/Instruction";
import Score from "../../components/score/Score";
import Result from "../../components/result/Result";
import CommonSnackBar from "../../components/common/CommonSnackBar";
import DialogSubmit from "../../components/submit/DialogSubmit";
import ReactGA from "react-ga";

const useStyles = makeStyles({
    root: {
        height: "100vh",
        width: "100vw",
        fontFamily: "'Poppins', sansSerif",
    },
    navbar: {
        backgroundColor: "#F5F8FF",
        height: "10vh",
        padding: "10px",
        border: "1px solid #CFD6E5",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    questionTitle: {
        margin: "0px auto",
        fontWeight: "bold",
        color: "#2B2E33",
    },
    countdown: {
        fontWeight: "bold",
        padding: "8px",
        color: "#234B88",
        minWidth: "233px",
    },
    containerAssessment: {
        height: "90vh",
        width: "100vw",
        display: "flex",
        justifyContent: "space-between",
    },
    containerSoal: {
        width: "50vw",
        fontWeight: "600",
        border: "1px solid #CFD6E5",
    },
    containerTab: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        padding: "10px",
        borderBottom: "1px solid #CFD6E5",
    },
    tab: {
        "&:hover": {
            cursor: "pointer",
            color: "#234B88",
        },
    },
    tabActive: {
        backgroundColor: "#CFD6E5",
        padding: "5px 15px",
        borderRadius: "5px",
    },
    textTabActive: {
        color: "#2761BA",
        fontSize: "15px",
    },
    textTabInactive: {
        color: "#6C7280",
    },
    containerNavigation: {
        padding: "20px",
    },
    injuryTime: {
        color: "#BF4C36",
        backgroundColor: "transparent",
        borderRadius: "8px",
        padding: "8px",
    },
    injuryBlink: {
        backgroundColor: "#FFECE9",
    },
});

const Compiler = () => {
    const classes = useStyles();
    const SERVER =
        "https://engine.coding.id/";
    const SERVER_RESULT =
        "http://ndsrndmain.southeastasia.cloudapp.azure.com/NawaEventAPI";

    useEffect(() => {
        if (process.env.REACT_APP_IS_TRACK === "true") {
            ReactGA.pageview("/test-assessment");
        }
    }, []);

    // data api coding.id
    const { dataAssessment, listQuestion, dataMember, timer, startTest } =
        useLocation().state;

    const [currentQuestion, setCurrentQuestion] = useState(1);
    // identity untuk mengenali language di backend
    const [languageId, setLanguageId] = useState(
        listQuestion[currentQuestion - 1].language[0].detail_language
            .editor_code
    );
    // identity untuk mengenali bahasa di ace editor
    const [languageCode, setLanguageCode] = useState(
        listQuestion[currentQuestion - 1].language[0].detail_language
            .language_code
    );
    const [input, setInput] = useState(
        unescapeJs(listQuestion[0].language[0].question_task)
    );
    const [sampleInput, setSampleInput] = useState(
        unescapeJs(listQuestion[0].data_question.question_input)
    );
    const [instruction, setInstruction] = useState(
        listQuestion[0].data_question.question_instruction
    );
    const [expectedOutput, setExpectedOutput] = useState(
        unescapeJs(listQuestion[0].data_question.question_output)
    );
    const [userOutput, setUserOutput] = useState("");
    const [timeLeft, setTimeLeft] = useState(startTest + Number(timer) * 60000);
    const [dialogReset, setDialogReset] = useState(false);
    const [tabActive, setTabActive] = useState("instruction");
    const [statusRun, setStatusRun] = useState("default");
    const [compileStatus, setcompileStatus] = useState("");
    const [arrayTC, setArrayTC] = useState([]);
    const [assessmentScore, setAssessmentScore] = useState([]);
    const [snackbarState, setSnackbarState] = useState({
        status: false,
        message: "Warning 5 Minutes",
        bgColor: "red",
        position: { vertical: "bottom", horizontal: "right" },
    });

    const [dialogFinish, setDialogFinish] = useState(false);

    const [scoreObject, setScoreObject] = useState({
        arrayQuestion: [],
        unsolvedQuestion: 0,
        totalQuestion: 0,
    });

    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(null);

    const [isInjuryTime, setInjuryTime] = useState(false);
    const [isInjuryBlink, setIsInjuryBlink] = useState(false);

    useEffect(() => {
        //whenever injury time, make block time is blinking
        let interval = "";
        if (isInjuryTime) {
            interval = setInterval(() => {
                setIsInjuryBlink((isInjuryBlink) => !isInjuryBlink);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isInjuryTime, setInjuryTime]);

    useEffect(() => {
        localStorage.getItem(
            `${
                dataAssessment.assessment_code + dataMember.id
            }_${currentQuestion}_${languageCode}`
        )
            ? setInput(
                  localStorage.getItem(
                      `${
                          dataAssessment.assessment_code + dataMember.id
                      }_${currentQuestion}_${languageCode}`
                  )
              )
            : unescapeJs(listQuestion[0].language[0].question_task);
    }, []);

    useEffect(() => {
        //console.log(JSON.stringify(localStorage.getItem(`results`)) + "results");
        if (
            localStorage.getItem(
                `${dataAssessment.assessment_code + dataMember.id}_results`
            )
        ) {
            let results = localStorage.getItem(
                `${dataAssessment.assessment_code + dataMember.id}_results`
            );
            setAssessmentScore(JSON.parse(results));
        }
    }, []);

    useEffect(() => {
        let result = Object.values(assessmentScore).flat();

        if (result.length == 0) {
            let tempScore = [];

            listQuestion.forEach((item, index) => {
                let maxScore = 0;

                for (let i = 0; i < item.test_case.length; i++) {
                    maxScore += Number(item.test_case[i].score);
                }

                let question = {
                    question_code: item.question_code,
                    question_name: item.data_question.question_name,
                    result: "-",
                    maxScore: maxScore,
                    language: "-",
                };

                tempScore.push(question);
            });

            setScoreObject({
                arrayQuestion: [...tempScore],
                unsolvedQuestion: listQuestion.length,
                totalQuestion: listQuestion.length,
            });
        } else {
            let tempScore = [];
            let solvedQuestion = 0;
            listQuestion.forEach((item, index) => {
                let maxScore = 0;

                for (let i = 0; i < item.test_case.length; i++) {
                    maxScore += Number(item.test_case[i].score);
                }
                let question = {
                    question_code: item.question_code,
                    question_name: item.data_question.question_name,
                    result: "-",
                    maxScore: maxScore,
                    language: "-",
                };

                result.forEach((item) => {
                    // resultScore += `Score question ${item.question_code} = ${item.score} (${item.language_code}) <br />`;
                    if (item.question_code == question.question_code) {
                        question.result = item.score;
                        question.language = item.language_code;
                        solvedQuestion++;
                    }
                });

                tempScore.push(question);
            });

            setScoreObject({
                arrayQuestion: [...tempScore],
                unsolvedQuestion: listQuestion.length - solvedQuestion,
                totalQuestion: listQuestion.length,
            });
        }
    }, [assessmentScore, setAssessmentScore]);

    const sleep = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
    };

    const changeQuestionHandler = (event) => {
        event.preventDefault();
        setArrayTC([]);
        setUserOutput("");
        setCurrentQuestion(event.target.value);

        let questionInput = unescapeJs(
            listQuestion[event.target.value - 1].data_question.question_input
        );
        setSampleInput(questionInput);

        if (
            listQuestion[event.target.value - 1].data_question
                .question_instruction
        ) {
            let questionInstruction = unescapeJs(
                listQuestion[event.target.value - 1].data_question
                    .question_instruction
            );

            setInstruction(questionInstruction);
        }

        let questionOutput = unescapeJs(
            listQuestion[event.target.value - 1].data_question.question_output
        );
        setExpectedOutput(questionOutput);

        if (
            localStorage.getItem(
                `${dataAssessment.assessment_code + dataMember.id}_${
                    event.target.value
                }_${languageCode}`
            )
        ) {
            setInput(
                localStorage.getItem(
                    `${dataAssessment.assessment_code + dataMember.id}_${
                        event.target.value
                    }_${languageCode}`
                )
            );
        } else {
            let languageObject = listQuestion[
                event.target.value - 1
            ].language.find((x) => x.language_code === languageCode);

            if (languageObject === undefined) {
                let questionObject =
                    listQuestion[event.target.value - 1].language[0];

                setLanguageId(questionObject.detail_language.editor_code);
                setLanguageCode(questionObject.detail_language.language_code);

                let questionTask = unescapeJs(questionObject.question_task);
                setInput(questionTask);
            } else {
                let questionTask = unescapeJs(languageObject.question_task);
                setInput(questionTask);
            }
        }
    };

    const changeLanguageHandler = (event) => {
        event.preventDefault();
        setLanguageId(event.target.value);
        let languageObject = listQuestion[currentQuestion - 1].language.find(
            (x) => x.detail_language.editor_code === event.target.value
        );

        setLanguageCode(languageObject.detail_language.language_code);
        let questionTask = unescapeJs(languageObject.question_task);
        setInput(
            localStorage.getItem(
                `${
                    dataAssessment.assessment_code + dataMember.id
                }_${currentQuestion}_${
                    languageObject.detail_language.language_code
                }`
            ) || questionTask
        );
    };

    const changeInputHandler = (newValue) => {
        setInput(newValue);
        // Save Code to Storage
        localStorage.setItem(
            `${
                dataAssessment.assessment_code + dataMember.id
            }_${currentQuestion}_${languageCode}`,
            newValue
        );
    };

    const resetHandler = () => {
        const listLanguage = listQuestion[currentQuestion - 1].language;
        const choosen_language = listLanguage.find(
            (x) => x.language_code === languageCode
        );
        const base_question = unescapeJs(choosen_language.question_task);

        setInput(base_question);
        localStorage.setItem(
            `${
                dataAssessment.assessment_code + dataMember.id
            }_${currentQuestion}_${languageCode}`,
            base_question
        );
        setDialogReset(false);
    };

    const tabChangeHandler = (tab) => {
        setTabActive(tab);
    };

    const renderTab = () => {
        if (tabActive == "instruction") {
            return <Instruction data={instruction} />;
        } else if (tabActive == "result") {
            return (
                <Result
                    statusRun={statusRun}
                    sampleInput={sampleInput}
                    expectedOutput={expectedOutput}
                    userOutput={userOutput}
                    arrayTC={arrayTC}
                    compileStatus={compileStatus}
                />
            );
        } else if (tabActive == "score") {
            return <Score scoreObject={scoreObject} />;
        }
    };

    const runHandler = async (e) => {
        if (input) {
            e.preventDefault();
            setTabActive("result");
            setStatusRun("loading");
            setArrayTC([]);
            setcompileStatus("Creating Submission...");
            setUserOutput("Creating Submission...");
            const response = await fetch(SERVER + "/submissions", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    accept: "application/json",
                },
                body: JSON.stringify({
                    source_code: input,
                    stdin: sampleInput,
                    language_id: languageId,
                    cpu_time_limit: 10,
                    cpu_extra_time: 5,
                    wall_time_limit: 20,
                }),
            }).catch((err) => {
                //console.log(err);
            });
            setcompileStatus("Submission Created ...");
            setUserOutput("Submission Created ...");
            const jsonResponse = await response.json();

            let jsonGetSolution = {
                status: { description: "Queue" },
                stderr: null,
                compile_output: null,
            };

            while (
                (jsonGetSolution.status.description == "Queue" ||
                    jsonGetSolution.status.description == "Processing") &&
                jsonGetSolution.stderr == null &&
                jsonGetSolution.compile_output == null
            ) {
                await sleep(1000);
                setcompileStatus("Checking Submission ...");
                setUserOutput("Checking Submission ...");
                if (jsonResponse.token) {
                    let url =
                        SERVER +
                        `/submissions/${jsonResponse.token}?base64_encoded=true`;

                    const getSolution = await fetch(url, {
                        method: "GET",
                        headers: {
                            "content-type": "application/json",
                        },
                    });

                    jsonGetSolution = await getSolution.json();
                }
            }

            if (jsonGetSolution.stdout) {
                const output = atob(jsonGetSolution.stdout);
                if (
                    output.trim() ==
                    unescapeJs(
                        listQuestion[currentQuestion - 1].data_question
                            .question_output
                    ).trim()
                ) {
                    setArrayTC([]);
                    setStatusRun("success");
                    setUserOutput(output);

                    const testCase =
                        listQuestion[currentQuestion - 1].test_case;

                    let score = 0;
                    let successCount = 0;
                    for (let i = 0; i < testCase.length; i++) {
                        let resultTC = await runTestCase(testCase[i], i);
                        score += resultTC.score;
                        if (resultTC.status == "success") {
                            successCount++;
                        }
                        setArrayTC((arrayTC) => [...arrayTC, resultTC.status]);
                    }
                    let failCount = testCase.length - successCount;
                    await SubmitAnswer(score);
                    setSnackbarState({
                        status: true,
                        message: `You have ${successCount} success case, and ${failCount} failed case`,
                        bgColor: "#163769",
                        position: { vertical: "bottom", horizontal: "right" },
                    });
                } else {
                    setStatusRun("success");
                    setUserOutput(output);
                    await SubmitAnswer(0);
                    setSnackbarState({
                        status: true,
                        message: "Your code is error. Please check again",
                        bgColor: "red",
                        position: { vertical: "bottom", horizontal: "left" },
                    });
                }
            } else if (jsonGetSolution.stderr) {
                const error = atob(jsonGetSolution.stderr);

                setUserOutput(`Error: ${error}`);
                setStatusRun("success");
                await SubmitAnswer(0);
                setSnackbarState({
                    status: true,
                    message: "Your code is error. Please check again",
                    bgColor: "red",
                    position: { vertical: "bottom", horizontal: "left" },
                });
            } else if (
                jsonGetSolution.status.description !== "Accepted" &&
                jsonGetSolution.compile_output == null
            ) {
                const status = jsonGetSolution.status.description;
                setUserOutput(`not accepted: ${status}`);
                setStatusRun("success");
                await SubmitAnswer(0);
                setSnackbarState({
                    status: true,
                    message: "Your code is error. Please check again",
                    bgColor: "red",
                    position: { vertical: "bottom", horizontal: "left" },
                });
            } else {
                const compilation_error = atob(jsonGetSolution.compile_output);
                setUserOutput(`Compilation Error: ${compilation_error}`);
                setStatusRun("success");
                await SubmitAnswer(0);
                setSnackbarState({
                    status: true,
                    message: "Your code is error. Please check again",
                    bgColor: "red",
                    position: { vertical: "bottom", horizontal: "left" },
                });
            }
        }
    };

    const runTestCase = async (objectTestCase, index) => {
        const response = await fetch(SERVER + "/submissions", {
            method: "POST",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            },
            body: JSON.stringify({
                source_code: input,
                stdin: unescapeJs(objectTestCase.input),
                language_id: languageId,
                cpu_time_limit: 10,
                cpu_extra_time: 5,
                wall_time_limit: 20,
            }),
        });

        const jsonResponse = await response.json();

        let jsonGetSolution = {
            status: { description: "Queue" },
            stderr: null,
            compile_output: null,
        };

        while (
            (jsonGetSolution.status.description == "Queue" ||
                jsonGetSolution.status.description == "Processing") &&
            jsonGetSolution.stderr == null &&
            jsonGetSolution.compile_output == null
        ) {
            await sleep(1000);
            if (jsonResponse.token) {
                let url =
                    SERVER +
                    `/submissions/${jsonResponse.token}?base64_encoded=true`;

                const getSolution = await fetch(url, {
                    method: "GET",
                    headers: {
                        "content-type": "application/json",
                    },
                });

                jsonGetSolution = await getSolution.json();
            }
        }

        let objectReturn = {
            score: 0,
            status: "failed",
        };

        if (jsonGetSolution.stdout) {
            const output = atob(jsonGetSolution.stdout);
            if (output.trim() == unescapeJs(objectTestCase.output).trim()) {
                objectReturn = {
                    score: parseInt(objectTestCase.score),
                    status: "success",
                };
            }
        }
        return objectReturn;
    };

    const SubmitAnswer = async (score) => {
        // console.log(score);

        // console.log("save result");
        if (assessmentScore[currentQuestion] == null) {
            setAssessmentScore({
                ...assessmentScore,
                [currentQuestion]: {
                    question_code:
                        listQuestion[currentQuestion - 1].question_code,
                    score: score == undefined ? 0 : score,
                    input: input,
                    language_code: languageCode,
                },
            });
        } else {
            setAssessmentScore({
                ...assessmentScore,
                [currentQuestion]: {
                    question_code:
                        listQuestion[currentQuestion - 1].question_code,
                    score: score == undefined ? 0 : score,
                    input: input,
                    language_code: languageCode,
                },
            });
        }

        localStorage.setItem(
            `${dataAssessment.assessment_code + dataMember.id}_results`,
            JSON.stringify({
                ...assessmentScore,
                [currentQuestion]: {
                    question_code:
                        listQuestion[currentQuestion - 1].question_code,
                    score: score == undefined ? 0 : score,
                    input: input,
                    language_code: languageCode,
                },
            })
        );
    };

    const dialogResetOpen = () => {
        setDialogReset(true);
    };

    const dialogResetClose = () => {
        setDialogReset(false);
    };

    const openSnackBar = (
        status = true,
        message = "Your remaining time is less than 5 minutes",
        bgColor = "red",
        position = { vertical: "bottom", horizontal: "right" }
    ) => {
        setSnackbarState((prev) => ({
            ...prev,
            status: status,
            message: message,
            bgColor: bgColor,
            position: position,
        }));
    };

    // check timeout of test
    const timerTick = ({ days, hours, minutes, seconds }) => {
        //console.log(days, hours, minutes, seconds);

        //set injury time if time remaining lower than 5 minutes
        if (days == 0 && hours == 0 && minutes < 5) {
            if (!isInjuryTime) {
                setInjuryTime(true);
            }
        }
        //open snackbar when time remaining is 5 minutes
        if (days == 0 && hours == 0 && minutes == 5 && seconds == "00") {
            openSnackBar(
                true,
                "Your remaining time is less than 5 minutes",
                "red",
                {
                    vertical: "bottom",
                    horizontal: "right",
                }
            );
        }
    };

    const rendererTime = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span className={classes.countdown}>Times Up!</span>;
        } else {
            if (days > 0) {
                return (
                    <span className={classes.countdown}>
                        Remaining Time = {zeroPad(days * 24 + hours)}:
                        {zeroPad(minutes)}:{zeroPad(seconds)}
                    </span>
                );
            } else {
                return (
                    <span
                        className={`${classes.countdown} ${
                            isInjuryTime ? classes.injuryTime : ""
                        } ${isInjuryBlink ? classes.injuryBlink : ""}`}
                    >
                        Remaining Time = {zeroPad(hours)}:{zeroPad(minutes)}:
                        {zeroPad(seconds)}
                    </span>
                );
            }
        }
    };

    const dialogFinishOpen = () => {
        setDialogFinish(true);
    };

    const dialogFinishClose = () => {
        //console.log("Close dialog finish");
        setDialogFinish(false);
    };

    const completeHandler = ({ completed }) => {
        if (completed) {
            finishHandler();
        }
    };

    const finishHandler = () => {
        setDialogFinish(false);
        setLoading(true);
        let arrayResult = Object.values(assessmentScore).flat();

        let hasil = 0;
        arrayResult.map((x) => (hasil += x.score));

        const dataResult = {
            assessment_code: dataAssessment.assessment_code,
            member_id: dataMember.id,
            hasil: hasil,
            answer: arrayResult,
        };

        Axios.post(
            `${process.env.REACT_APP_API_END_POINT}/assessment/submitresult`,
            dataResult
        )
            .then((res) => {
                console.log(res);
                //localStorage.clear();
                // localStorage.removeItem(
                //   `${dataAssessment.assessment_code}_${currentProblem}_${languageCode}_results`
                // );

                //remove local storage with same assessment code
                let idInString = String(dataMember.id);
                Object.entries(localStorage)
                    .map((x) => x[0])
                    .filter(
                        (x) =>
                            x.substring(
                                0,
                                dataAssessment.assessment_code.length +
                                    idInString.length
                            ) ==
                            `${dataAssessment.assessment_code + dataMember.id}`
                    )
                    .map((x) => localStorage.removeItem(x));

                setLoading(false);
                setRedirect({ pathname: "/finish" });
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);

                if (err.response) {
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    Swal.fire({
                        text: err.response.data.error,
                        icon: "error",
                        showCloseButton: true,
                    });
                } else {
                    Swal.fire({
                        text: "something was wrong",
                        icon: "error",
                        showCloseButton: true,
                    });
                }
            });
    };

    const renderRedirect = () => {
        if (redirect) {
            return <Redirect to={redirect} />;
        }
    };

    if (loading) {
        return <Loading />;
    } else {
        return (
            <>
                {renderRedirect()}
                <div className={classes.root}>
                    <div className={classes.navbar}>
                        <img src={logo} alt="" />
                        <p className={classes.questionTitle}>
                            {`${
                                listQuestion[currentQuestion - 1].question_code
                            } - ${
                                listQuestion[currentQuestion - 1].data_question
                                    .question_name
                            } `}
                        </p>
                        <Countdown
                            date={timeLeft}
                            onComplete={completeHandler}
                            renderer={rendererTime}
                            daysInHours={true}
                            onTick={timerTick}
                        />
                    </div>
                    <div className={classes.containerAssessment}>
                        <UserInput
                            listQuestion={listQuestion}
                            currentQuestion={currentQuestion}
                            changeQuestionHandler={changeQuestionHandler}
                            languageCode={languageCode}
                            input={input}
                            languageId={languageId}
                            changeLanguageHandler={changeLanguageHandler}
                            changeInputHandler={changeInputHandler}
                            resetHandler={resetHandler}
                            dialogReset={dialogReset}
                            dialogResetOpen={dialogResetOpen}
                            dialogResetClose={dialogResetClose}
                            runHandler={runHandler}
                        />
                        <div className={classes.containerSoal}>
                            <div className={classes.containerTab}>
                                <div
                                    className={`${classes.tab} ${
                                        tabActive == "instruction"
                                            ? classes.tabActive
                                            : ""
                                    }
                            `}
                                    onClick={() =>
                                        tabChangeHandler("instruction")
                                    }
                                >
                                    <span
                                        className={`${
                                            tabActive == "instruction"
                                                ? classes.textTabActive
                                                : classes.textTabInactive
                                        }`}
                                    >
                                        <InstructionIcon
                                            fontSize="small"
                                            style={{ marginRight: "10px" }}
                                        />
                                        Instructions
                                    </span>
                                </div>
                                <div
                                    className={`${classes.tab} ${
                                        tabActive == "result"
                                            ? classes.tabActive
                                            : ""
                                    }
                            `}
                                    onClick={() => tabChangeHandler("result")}
                                >
                                    <span
                                        className={`${
                                            tabActive == "result"
                                                ? classes.textTabActive
                                                : classes.textTabInactive
                                        }`}
                                    >
                                        <CodeIcon
                                            fontSize="small"
                                            style={{ marginRight: "10px" }}
                                        />
                                        Result
                                    </span>
                                </div>
                                <div
                                    className={`${classes.tab} ${
                                        tabActive == "score"
                                            ? classes.tabActive
                                            : ""
                                    }
                            `}
                                    onClick={() => tabChangeHandler("score")}
                                >
                                    <span
                                        className={`${
                                            tabActive == "score"
                                                ? classes.textTabActive
                                                : classes.textTabInactive
                                        }`}
                                    >
                                        <AssignmentTurnedInIcon
                                            fontSize="small"
                                            style={{ marginRight: "10px" }}
                                        />
                                        Score
                                    </span>
                                </div>
                                <Button
                                    onClick={dialogFinishOpen}
                                    variant="contained"
                                    color="primary"
                                >
                                    Submit
                                </Button>
                            </div>
                            <div className={classes.containerNavigation}>
                                {renderTab()}
                            </div>
                        </div>
                    </div>
                    <CommonSnackBar
                        open={snackbarState.status}
                        message={snackbarState.message}
                        handleClose={() =>
                            setSnackbarState((prev) => ({
                                ...prev,
                                status: false,
                            }))
                        }
                        position={snackbarState.position}
                        bgColor={snackbarState.bgColor}
                    />

                    <DialogSubmit
                        dialogFinishClose={dialogFinishClose}
                        dialogFinish={dialogFinish}
                        finishHandler={finishHandler}
                        scoreObject={scoreObject}
                    />
                </div>
            </>
        );
    }
};

export default Compiler;
