import { makeStyles } from "@material-ui/core/styles";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import { PlayCircleOutline, Cached } from "@material-ui/icons";

// text editor
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-dracula";
import "ace-builds/src-noconflict/ext-language_tools";

const useStyles = makeStyles({
    containerInput: {
        padding: "20px",
        width: "50vw",
        // backgroundColor: "#54ca68",
    },
    dropdownInput: {
        width: "100%",
        display: "inline-flex",
        alignItems: "center",
    },
    containerSoal: {
        padding: "20px",
        width: "50vw",
        backgroundColor: "#3abaf4",
        fontWeight: "600",
    },
    containerButtonInput: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "10px",
    },
    buttonRun: {
        color: "#fff",
        textTransform: "none",
        fontSize: "12px",
        backgroundColor: "#2761BA",
        border: "1px solid #F4991E",
        borderRadius: "5px",
        padding: "7px 30px",
        "&:hover": {
            backgroundColor: "#2761BA",
            color: "#fff",
        },
    },
    buttonReset: {
        color: "#234B88",
        textTransform: "none",
        fontSize: "12px",
        backgroundColor: "#FFF",
        border: "1px solid #234B88",
        borderRadius: "5px",
        padding: "7px 30px",
        "&:hover": {
            backgroundColor: "#FFF",
            color: "#234B88",
        },
    },
    buttonPopUpCancel: {
        color: "#2761BA",
        textTransform: "none",
        fontFamily: "'Poppins', sansSerif",
        backgroundColor: "#fff",
        border: "1px solid #2761BA",
        padding: "12px 48.5px",
    },
    buttonPopUpAction: {
        color: "#fff",
        textTransform: "none",
        fontFamily: "'Poppins', sansSerif",
        backgroundColor: "#2761BA",
        border: "1px solid #2761BA",
        padding: "12px 48.5px",
    },
});

const UserInput = (props) => {
    const classes = useStyles();
    const {
        listQuestion,
        currentQuestion,
        changeQuestionHandler,
        languageCode,
        input,
        languageId,
        changeLanguageHandler,
        changeInputHandler,
        resetHandler,
        dialogReset,
        dialogResetOpen,
        dialogResetClose,
        runHandler,
    } = props;

    return (
        <div className={classes.containerInput}>
            <div className={classes.dropdownInput}>
                <div>
                    <label htmlFor="problems">
                        <p>Questions</p>
                    </label>

                    <select
                        value={currentQuestion}
                        onChange={changeQuestionHandler}
                        id="problems"
                        className="form-control form-inline mb-2 mx-2 language"
                    >
                        {listQuestion.map((item, index) => (
                            <option
                                key={index}
                                value={index + 1}
                            >{`${item.question_code} - ${item.data_question.question_name}`}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="language">
                        <p>Language</p>
                    </label>
                    <select
                        value={languageId}
                        onChange={changeLanguageHandler}
                        id="tags"
                        className="form-control form-inline mb-2 mx-2 language"
                    >
                        {listQuestion[currentQuestion - 1].language.map(
                            (item, index) => (
                                <option
                                    key={index}
                                    value={item.detail_language.editor_code}
                                >{`${item.detail_language.name}`}</option>
                            )
                        )}
                    </select>
                </div>
            </div>
            <div>
                <AceEditor
                    id="source"
                    className=" source"
                    placeholder="Source Code"
                    mode={languageCode}
                    theme="dracula"
                    name="solution"
                    onChange={changeInputHandler}
                    fontSize={14}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    value={input}
                    width="100%"
                    height="60vh"
                    setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        tabSize: 4,
                        wrap: true,
                    }}
                />
            </div>
            <div className={classes.containerButtonInput}>
                <Button
                    className={classes.buttonReset}
                    onClick={dialogResetOpen}
                    variant="outlined"
                >
                    <Cached fontSize="small" style={{ marginRight: "5px" }} />
                    <span style={{ margin: "0px auto" }}>Reset Code</span>
                </Button>
                <Dialog
                    open={dialogReset}
                    onClose={dialogResetClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent dividers>
                        <DialogContentText
                            style={{ textAlign: "center" }}
                            id="alert-dialog-description"
                        >
                            <p>Your written code will be removed</p>
                            <p>are you sure to reset the code?</p>
                            <div style={{ paddingTop: "10px" }}>
                                <Button
                                    className={classes.buttonPopUpCancel}
                                    onClick={dialogResetClose}
                                    variant="contained"
                                    style={{ marginLeft: "10px" }}
                                >
                                    <span style={{ margin: "0px auto" }}>
                                        Cancel
                                    </span>
                                </Button>
                                <Button
                                    className={classes.buttonPopUpAction}
                                    onClick={resetHandler}
                                    variant="contained"
                                    style={{ marginLeft: "10px" }}
                                >
                                    <span style={{ margin: "0px auto" }}>
                                        Reset
                                    </span>
                                </Button>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Button
                    className={classes.buttonRun}
                    onClick={runHandler}
                    variant="contained"
                    style={{ marginLeft: "10px" }}
                >
                    <PlayCircleOutline
                        fontSize="small"
                        style={{ marginRight: "5px" }}
                    />
                    <span style={{ margin: "0px auto" }}>Run Code</span>
                </Button>
            </div>
        </div>
    );
};

export default UserInput;
